.listing-section {
  padding-bottom: 10px;
  padding-top: 20px;
}
.listing-section .heading h3 {
  color: #191919;
}
.listing-section .section-heading {
  text-align: center;
  width: 100%;
}
.listing-section .section-heading h2,
.listing-section .sub-heading h3 {
  color: #191919;
}
.listing-section .sub-heading span {
  font-weight: 600;
}
.listing-section .job-heading-content p {
  margin-bottom: 2px;
}

.listing-section .sub-heading {
  text-align: left;
}

.listings .card-header .btn-link {
  text-decoration: none;
  color: #0f5ca1;
  font-size: 16px;
  transition: all 0.3s;
}

.listings .card-header .btn-link:hover {
  color: #de3026;
}
