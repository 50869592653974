@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap");
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: #fff;
}

body,
button,
input,
select,
optgroup,
textarea {
  color: #595959;
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #0f5ca1;
}

p {
  margin-bottom: 20px;
}

section {
  padding: 50px 0px 0px 0px;
}

.add-margin-bottom {
  margin-bottom: 20px;
}

.carousel-arrow:hover {
  transform: scale(1.2);
  color: #de3026 !important;
}

.rec-dot {
  border: 1px solid #a3a3a3!important;
  box-shadow: none!important;
  height: 13px!important;
  background-color: #a3a3a3!important;
  
}

.rec-dot_active {
  background-color: #191919!important;
  box-shadow: none!important;
}
