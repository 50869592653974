.jumbotron-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../images/landing-image.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 30px 100px 50px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.jumbotron-custom .main-heading {
  padding: 100px 0px 0px 0px;
}

.jumbotron-custom .main-heading,
.jumbotron-custom .sub-heading {
  text-align: center;
}

.jumbotron-custom .main-heading h1 {
  font-size: 60px;
  font-weight: 500;
  transition: all 0.5s ease;
}

.jumbotron-custom .sub-heading {
  padding: 0 80px 0 80px;
}

@media(min-width: 1024px){
  .jumbotron-custom .sub-heading {
    padding: 0 250px 0 250px;
  }
}


.jumbotron-custom .sub-heading p {
  font-size: 22px;
  transition: all 0.5s ease;
}

.jumbotron-custom .heading-button {
  margin: 50px 0px 0px 0px;
}

.jumbotron-custom .main-button {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  background-color: #de3026;
  border-radius: 30px 30px 30px 30px;
  padding: 15px 33px 15px 33px;
  color: #fff;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #de3026;
  transition: all 0.3s ease;
}

.jumbotron-custom .main-button:hover {
  text-decoration: none;
  color: #fff;
  background: #0f5ca1;
  border-color: #0f5ca1;
}

@media (max-width: 1024px) {
  .jumbotron-custom .main-heading h1 {
    font-size: 48px;
  }

  .jumbotron-custom .sub-heading p {
    font-size: 16px;
  }
  .jumbotron-custom .sub-heading {
    padding: 0 150px 0 150px;
  }
}

@media (max-width: 767px) {
  .jumbotron-custom .main-heading h1 {
    font-size: 30px;
  }
  .jumbotron-custom .sub-heading {
    padding: 0 10px 0 10px;
  }
}
