.contact-section {
  padding-top: 200px;
}
.contact-section .contact-heading {
  margin-bottom: 20px;
}
.contact-section .contact-heading h5 {
  color: #191919;
  font-size: 20px;
}
.contact-company p {
  margin-bottom: 2px;
}
.contact-section {
  padding-bottom: 100px;
}
.contact-section .heading h3 {
  color: #191919;
}
.contact-section .section-heading {
  text-align: center;
  width: 100%;
}
.contact-section .section-heading h2 {
  color: #191919;
}
