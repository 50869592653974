.active {
  color: #de3026 !important;
}
.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-container ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.menu-container ul li {
  display: inline-block;
  padding: 0 25px;
  font-weight: 500;
}

.menu-container ul li:first-of-type {
  padding-left: 15px;
}
.mobile-toggle {
  font-size: 3rem;
  transition: all 0.5s;
  color: blue;
}

.mobile-menu-toggle {
  position: relative;
  background: transparent;
  border: 0;
  padding: 15px 20px;
}
.search {
  margin-left: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-navigation {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  float: left;
  overflow: hidden;
}

.site-navigation a {
  float: left;
  display: block;
  color: #0f5ca1;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

.site-navigation a:hover {
  color: #de3026;
}
.site-header {
  position: fixed;
  width: 100%;
  padding: 40px 0;
  transition: all 0.4s ease-in-out;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
}

.affix {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  z-index: 999;
}

.container-fluid {
  padding: 0 75px;
}

.header-mobile-menu {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1224px) {
  .site-navigation {
    display: none;
  }
}
