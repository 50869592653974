.choices-section {
  background-color: #f7f7f7;
  margin-bottom: 0px;
  padding-bottom: 50px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
.choices-homepage-content .heading h3 {
  color: #191919;
  width: 100%;
  text-align: center;
}

.choices-homepage-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0px 0px 10px 0px;
}
