@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap);
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: #fff;
}

body,
button,
input,
select,
optgroup,
textarea {
  color: #595959;
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;
  color: #0f5ca1;
}

p {
  margin-bottom: 20px;
}

section {
  padding: 50px 0px 0px 0px;
}

.add-margin-bottom {
  margin-bottom: 20px;
}

.carousel-arrow:hover {
  transform: scale(1.2);
  color: #de3026 !important;
}

.rec-dot {
  border: 1px solid #a3a3a3!important;
  box-shadow: none!important;
  height: 13px!important;
  background-color: #a3a3a3!important;
  
}

.rec-dot_active {
  background-color: #191919!important;
  box-shadow: none!important;
}

.active {
  color: #de3026 !important;
}
.menu-link {
  padding: 25px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  text-align: center;
}
.mobile-menu-container {
  position: fixed;
  top: 7rem;
  display: flex;
  flex-direction: column;
  z-index: 999;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: transform 0.4s 0s ease, opacity 0.4s 0s ease,
    visibility 0s 0s linear;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb;
}

.mobile-menu-visible {
  position: fixed;
  top: 7rem;
  display: flex;
  flex-direction: column;
  z-index: 999;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: transform 0.4s 0s ease, opacity 0.4s 0s ease,
    visibility 0s 0s linear;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb;
}

.mobile-menu-visible a {
  font-weight: 500;
  font-size: 17px;
}
.mobile-menu-visible a:hover {
  text-decoration: none;
  color: #de3026;
}

.navTrigger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 0.2rem;
  transition: all 0.2s ease-in-out;
}

.navTrigger i {
  background-color: #0f5ca1;
  border-radius: 2px;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
}
.navTrigger i:nth-child(1) {
  animation: outT 0.4s backwards;
  animation-direction: reverse;
}
.navTrigger i:nth-child(2) {
  margin: 5px 0;
  animation: outM 0.4s backwards;
  animation-direction: reverse;
}
.navTrigger i:nth-child(3) {
  animation: outBtm 0.4s backwards;
  animation-direction: reverse;
}
.navTrigger.active i:nth-child(1) {
  animation: inT 0.4s forwards;
}
.navTrigger.active i:nth-child(2) {
  animation: inM 0.4s forwards;
}
.navTrigger.active i:nth-child(3) {
  animation: inBtm 0.4s forwards;
}
@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}
@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}
@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}
@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

.active {
  color: #de3026 !important;
}
.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-container ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.menu-container ul li {
  display: inline-block;
  padding: 0 25px;
  font-weight: 500;
}

.menu-container ul li:first-of-type {
  padding-left: 15px;
}
.mobile-toggle {
  font-size: 3rem;
  transition: all 0.5s;
  color: blue;
}

.mobile-menu-toggle {
  position: relative;
  background: transparent;
  border: 0;
  padding: 15px 20px;
}
.search {
  margin-left: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-navigation {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  float: left;
  overflow: hidden;
}

.site-navigation a {
  float: left;
  display: block;
  color: #0f5ca1;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

.site-navigation a:hover {
  color: #de3026;
}
.site-header {
  position: fixed;
  width: 100%;
  padding: 40px 0;
  transition: all 0.4s ease-in-out;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
}

.affix {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  z-index: 999;
}

.container-fluid {
  padding: 0 75px;
}

.header-mobile-menu {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1224px) {
  .site-navigation {
    display: none;
  }
}

.jumbotron-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../../static/media/landing-image.38780c53.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 30px 100px 50px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.jumbotron-custom .main-heading {
  padding: 100px 0px 0px 0px;
}

.jumbotron-custom .main-heading,
.jumbotron-custom .sub-heading {
  text-align: center;
}

.jumbotron-custom .main-heading h1 {
  font-size: 60px;
  font-weight: 500;
  transition: all 0.5s ease;
}

.jumbotron-custom .sub-heading {
  padding: 0 80px 0 80px;
}

@media(min-width: 1024px){
  .jumbotron-custom .sub-heading {
    padding: 0 250px 0 250px;
  }
}


.jumbotron-custom .sub-heading p {
  font-size: 22px;
  transition: all 0.5s ease;
}

.jumbotron-custom .heading-button {
  margin: 50px 0px 0px 0px;
}

.jumbotron-custom .main-button {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  background-color: #de3026;
  border-radius: 30px 30px 30px 30px;
  padding: 15px 33px 15px 33px;
  color: #fff;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #de3026;
  transition: all 0.3s ease;
}

.jumbotron-custom .main-button:hover {
  text-decoration: none;
  color: #fff;
  background: #0f5ca1;
  border-color: #0f5ca1;
}

@media (max-width: 1024px) {
  .jumbotron-custom .main-heading h1 {
    font-size: 48px;
  }

  .jumbotron-custom .sub-heading p {
    font-size: 16px;
  }
  .jumbotron-custom .sub-heading {
    padding: 0 150px 0 150px;
  }
}

@media (max-width: 767px) {
  .jumbotron-custom .main-heading h1 {
    font-size: 30px;
  }
  .jumbotron-custom .sub-heading {
    padding: 0 10px 0 10px;
  }
}

.chart-section {
  padding-bottom: 100px;
}
.chart-section .heading h3 {
  color: #191919;
  transition: all 0.4s;
}
.chart-element {
  margin-bottom: 40px;
}

.chart-image {
  display: flex;
  justify-content: center;
}

.chart-image img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.chart-button {
  margin: 50px 0px 0px 0px;
  transition: all 0.4s;
}

.chart-button-read {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  fill: #0f5ca1;
  color: #0f5ca1;
  background-color: #ffffff;
  border-radius: 30px 30px 30px 30px;
  padding: 15px 33px 15px 33px;
  transition: all 0.3s;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #de3026;
}

.chart-button-read:hover {
  color: #fff;
  background: #de3026;
  text-decoration: none;
}

@media (max-width: 768px) {
  .chart-button {
    margin-bottom: 50px;
    text-align: center;
  }

  .chart-section .heading h2 {
    text-align: center;
    font-size: 24px;
  }

  .chart-text-content {
    text-align: justify;
    text-justify: inter-word;
  }
}

.intouch-section {
  background-color: #0f5ca1;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 0px 100px 0px;
  margin-bottom: 0;
}

.intouch-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.intouch-section .subtext p {
  color: #fff;
}

.intouch-section .heading {
  margin-bottom: 20px;
}

.intouch-section .heading h2 {
  font-size: 42px;
  line-height: 48px;
  transition: all 0.4s;
}

@media (max-width: 768px) {
  .intouch-section .heading h2 {
    font-size: 24px;
    text-align: center;
  }
}

.subtext {
  text-align: center;
}

.subtext p {
  font-size: 18px;
}

.intouch-element {
  margin: 50px 0px 0px 0px;
  text-align: center;
}

.intouch-button {
  margin-top: 100px;
}

.intouch-button a {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  fill: #de3026;
  color: #de3026;
  background-color: #ffffff;
  border-radius: 30px 30px 30px 30px;
  padding: 15px 33px 15px 33px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #ffffff;
}

.intouch-button a:hover {
  color: #fff;
  background: #de3026;
  text-decoration: none;
}

.service-title h3 {
  color: #191919;
}
.service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 410px;
  align-items: center;
  padding: 30px 20px 20px 20px;
  background: #f7f7f7;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.service-container:hover {
  transform: translateY(-2px);
 
}
.service-container .service-icon {
  margin-bottom: 20px;
}
.service-container .service-icon img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  transition-duration: 0.3s;
}

.service-container .service-title {
  margin-bottom: 5px;
  text-align: center;
}

.service-container .service-desc {
  text-align: center;
}
.service-container .service-title h3 {
  font-size: 18px;
}
.service-container .service-button a {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  fill: #191919;
  color: #191919;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 3px;
}

.service-container .service-button a:hover {
  color: #de3026;
  text-decoration: none;
}

@media (max-width: 994px) and (min-width: 769px) {
  .service-container .service-button a {
    font-size: 10px;
    font-weight: 600;
  }
}

@media(max-width: 768px){
  .service-container {
    justify-content: flex-start;
    margin-bottom: 2rem;
    min-height: 210px;
  }
}

.services-homepage {
  padding-top: 0;
  background-color: #fff;
  padding-bottom: 50px;
}

.home-page-service-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 10px 0px;
}
.services-homepage .subtext {
  margin: 0px 0px 65px 0px;
}

.services-homepage .heading {
  margin-bottom: 10px !important;
}

.services-homepage .heading h3 {
  color: #191919;
}
.services-homepage .subtext p {
  color: #595959;
  font-size: 18px;
}
.services-homepage .service-content {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .services-homepage .subtext {
    text-align: center;
  }
}

.choice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 15px;
  border-radius: 10px;
  min-height: 36rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.choice-container .choice-icon {
  margin-bottom: 40px;
  background: #bdbdbd;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choice-container .choice-icon img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  transition-duration: 0.3s;
}

.choice-title {
  margin-bottom: 12px;
  text-align: center;
}

.choice-container .service-desc {
  text-align: center;
}
.choice-title h2 {
  font-size: 20px;
  color: #191919;
}

@media (max-width: 768px) {
  .choice-container {
    min-height: 13rem;
    margin-bottom: 2rem;
  }
}

.choices-section {
  background-color: #f7f7f7;
  margin-bottom: 0px;
  padding-bottom: 50px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
.choices-homepage-content .heading h3 {
  color: #191919;
  width: 100%;
  text-align: center;
}

.choices-homepage-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0px 0px 10px 0px;
}

.carousel-div-item {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.carousel-div-item:hover{
  transform: translateY(-2px);
}

.clients-section {
  padding-bottom: 50px;
}
.clients-carousel {
  width: 100%;
}
.clients-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 40px 0px;
}

.clients-content .heading {
  margin-bottom: 40px;
}
.clients-section .heading h3 {
  color: #191919;
}


footer {
  padding-top: 80px;
  padding-bottom: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top: 1px solid #ebebeb;
  background-color: #f7f7f7;
}

.quick-links-content a {
  text-decoration: none;
  font-weight: 500;
}

.quick-links-content a:hover {
  color: #de3026;
}

.heading h4 {
  color: #191919;
}

.contacts-content .title {
  color: #191919;
  font-weight: 500;
}

.our-services-section .description {
  padding-top: 20px;
  padding-bottom: 20px;
}
.our-services-section .content {
  border-radius: 10px;
  padding: 0;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.our-services-section .service-item {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .our-services-section .service-icon {
    height: 334px;
  }
}

.our-services-section .title {
  color: #0f5ca1;
  line-height: 1;
  text-align: left;
}

.our-services-section {
  padding-bottom: 50px;
  padding-top: 200px;
}
.our-services-section .heading h3 {
  color: #191919;
}
.our-services-section .section-heading {
  text-align: center;
  width: 100%;
}
.our-services-section .section-heading h2 {
  color: #191919;
}

.who-we-are-section {
  padding-top: 200px;
  padding-bottom: 50px;
}
.who-we-are-section .heading h3 {
  color: #191919;
}
.who-we-are-section .section-heading {
  text-align: center;
  width: 100%;
}
.who-we-are-section .section-heading h2 {
  color: #191919;
}
.who-we-are-section .chart-image img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}


.mission-section {
  padding-bottom: 50px;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.ethics-section {
  padding-bottom: 50px;
}

.ethics-section .title h3 {
  color: #0f5ca1;
}

.ethics-section .title,
.ethics-section .description,
.ethics-section .ethics-icon {
  text-align: center;
}

.submit-form-btn,
.close-modal-btn {
  transition: all 0.4s;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  fill: #0f5ca1;
  color: #0f5ca1;
  background-color: #ffffff;
  border-radius: 30px 30px 30px 30px;
  padding: 10px 25px 10px 25px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #0f5ca1;
  margin-top: 25px;
}

.submit-form-btn:hover,
.close-modal-btn:hover {
  background-color: #0f5ca1;
  color: #fff;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem #66aff0;
}

.modal-title {
  width: 100%;
  text-align: center;
}

.modal-title span {
  font-size: 16px;
}

.modal-body {
  text-align: center;
}

.modal-footer {
  justify-content: center !important;
}

.modal-content {
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
}

.contact-section {
  padding-top: 200px;
}
.contact-section .contact-heading {
  margin-bottom: 20px;
}
.contact-section .contact-heading h5 {
  color: #191919;
  font-size: 20px;
}
.contact-company p {
  margin-bottom: 2px;
}
.contact-section {
  padding-bottom: 100px;
}
.contact-section .heading h3 {
  color: #191919;
}
.contact-section .section-heading {
  text-align: center;
  width: 100%;
}
.contact-section .section-heading h2 {
  color: #191919;
}

.listing-section {
  padding-bottom: 10px;
  padding-top: 20px;
}
.listing-section .heading h3 {
  color: #191919;
}
.listing-section .section-heading {
  text-align: center;
  width: 100%;
}
.listing-section .section-heading h2,
.listing-section .sub-heading h3 {
  color: #191919;
}
.listing-section .sub-heading span {
  font-weight: 600;
}
.listing-section .job-heading-content p {
  margin-bottom: 2px;
}

.listing-section .sub-heading {
  text-align: left;
}

.listings .card-header .btn-link {
  text-decoration: none;
  color: #0f5ca1;
  font-size: 16px;
  transition: all 0.3s;
}

.listings .card-header .btn-link:hover {
  color: #de3026;
}

.listings a:hover {
  text-decoration: none;
  color: #de3026;
}
.careers-section {
  padding-bottom: 50px;
  padding-top: 200px;
}
.careers-section .heading h3 {
  color: #191919;
}
.careers-section .section-heading {
  text-align: center;
  width: 100%;
}
.careers-section .section-heading h2 {
  color: #191919;
}

.not-found-section {
  padding-top: 200px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.not-found-message {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

