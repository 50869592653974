@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap");

.submit-form-btn,
.close-modal-btn {
  transition: all 0.4s;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  fill: #0f5ca1;
  color: #0f5ca1;
  background-color: #ffffff;
  border-radius: 30px 30px 30px 30px;
  padding: 10px 25px 10px 25px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #0f5ca1;
  margin-top: 25px;
}

.submit-form-btn:hover,
.close-modal-btn:hover {
  background-color: #0f5ca1;
  color: #fff;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem #66aff0;
}

.modal-title {
  width: 100%;
  text-align: center;
}

.modal-title span {
  font-size: 16px;
}

.modal-body {
  text-align: center;
}

.modal-footer {
  justify-content: center !important;
}

.modal-content {
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
}
