.who-we-are-section {
  padding-top: 200px;
  padding-bottom: 50px;
}
.who-we-are-section .heading h3 {
  color: #191919;
}
.who-we-are-section .section-heading {
  text-align: center;
  width: 100%;
}
.who-we-are-section .section-heading h2 {
  color: #191919;
}
.who-we-are-section .chart-image img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
