.intouch-section {
  background-color: #0f5ca1;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 0px 100px 0px;
  margin-bottom: 0;
}

.intouch-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.intouch-section .subtext p {
  color: #fff;
}

.intouch-section .heading {
  margin-bottom: 20px;
}

.intouch-section .heading h2 {
  font-size: 42px;
  line-height: 48px;
  transition: all 0.4s;
}

@media (max-width: 768px) {
  .intouch-section .heading h2 {
    font-size: 24px;
    text-align: center;
  }
}

.subtext {
  text-align: center;
}

.subtext p {
  font-size: 18px;
}

.intouch-element {
  margin: 50px 0px 0px 0px;
  text-align: center;
}

.intouch-button {
  margin-top: 100px;
}

.intouch-button a {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  fill: #de3026;
  color: #de3026;
  background-color: #ffffff;
  border-radius: 30px 30px 30px 30px;
  padding: 15px 33px 15px 33px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #ffffff;
}

.intouch-button a:hover {
  color: #fff;
  background: #de3026;
  text-decoration: none;
}
