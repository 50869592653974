.clients-section {
  padding-bottom: 50px;
}
.clients-carousel {
  width: 100%;
}
.clients-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 40px 0px;
}

.clients-content .heading {
  margin-bottom: 40px;
}
.clients-section .heading h3 {
  color: #191919;
}

