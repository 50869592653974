.not-found-section {
  padding-top: 200px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}

.not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.not-found-message {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
