.services-homepage {
  padding-top: 0;
  background-color: #fff;
  padding-bottom: 50px;
}

.home-page-service-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 10px 0px;
}
.services-homepage .subtext {
  margin: 0px 0px 65px 0px;
}

.services-homepage .heading {
  margin-bottom: 10px !important;
}

.services-homepage .heading h3 {
  color: #191919;
}
.services-homepage .subtext p {
  color: #595959;
  font-size: 18px;
}
.services-homepage .service-content {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .services-homepage .subtext {
    text-align: center;
  }
}
