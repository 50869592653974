.choice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 15px;
  border-radius: 10px;
  min-height: 36rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.choice-container .choice-icon {
  margin-bottom: 40px;
  background: #bdbdbd;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choice-container .choice-icon img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  transition-duration: 0.3s;
}

.choice-title {
  margin-bottom: 12px;
  text-align: center;
}

.choice-container .service-desc {
  text-align: center;
}
.choice-title h2 {
  font-size: 20px;
  color: #191919;
}

@media (max-width: 768px) {
  .choice-container {
    min-height: 13rem;
    margin-bottom: 2rem;
  }
}
