footer {
  padding-top: 80px;
  padding-bottom: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top: 1px solid #ebebeb;
  background-color: #f7f7f7;
}

.quick-links-content a {
  text-decoration: none;
  font-weight: 500;
}

.quick-links-content a:hover {
  color: #de3026;
}

.heading h4 {
  color: #191919;
}

.contacts-content .title {
  color: #191919;
  font-weight: 500;
}
