.navTrigger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 0.2rem;
  transition: all 0.2s ease-in-out;
}

.navTrigger i {
  background-color: #0f5ca1;
  border-radius: 2px;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
}
.navTrigger i:nth-child(1) {
  -webkit-animation: outT 0.4s backwards;
  animation: outT 0.4s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}
.navTrigger i:nth-child(2) {
  margin: 5px 0;
  -webkit-animation: outM 0.4s backwards;
  animation: outM 0.4s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}
.navTrigger i:nth-child(3) {
  -webkit-animation: outBtm 0.4s backwards;
  animation: outBtm 0.4s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}
.navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.4s forwards;
  animation: inT 0.4s forwards;
}
.navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.4s forwards;
  animation: inM 0.4s forwards;
}
.navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.4s forwards;
  animation: inBtm 0.4s forwards;
}
@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}
@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}
@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}
@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}
@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}
@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}
@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}
@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}
@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}
@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}
