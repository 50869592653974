.chart-section {
  padding-bottom: 100px;
}
.chart-section .heading h3 {
  color: #191919;
  transition: all 0.4s;
}
.chart-element {
  margin-bottom: 40px;
}

.chart-image {
  display: flex;
  justify-content: center;
}

.chart-image img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.chart-button {
  margin: 50px 0px 0px 0px;
  transition: all 0.4s;
}

.chart-button-read {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  fill: #0f5ca1;
  color: #0f5ca1;
  background-color: #ffffff;
  border-radius: 30px 30px 30px 30px;
  padding: 15px 33px 15px 33px;
  transition: all 0.3s;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #de3026;
}

.chart-button-read:hover {
  color: #fff;
  background: #de3026;
  text-decoration: none;
}

@media (max-width: 768px) {
  .chart-button {
    margin-bottom: 50px;
    text-align: center;
  }

  .chart-section .heading h2 {
    text-align: center;
    font-size: 24px;
  }

  .chart-text-content {
    text-align: justify;
    text-justify: inter-word;
  }
}
