.our-services-section .description {
  padding-top: 20px;
  padding-bottom: 20px;
}
.our-services-section .content {
  border-radius: 10px;
  padding: 0;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.our-services-section .service-item {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .our-services-section .service-icon {
    height: 334px;
  }
}

.our-services-section .title {
  color: #0f5ca1;
  line-height: 1;
  text-align: left;
}

.our-services-section {
  padding-bottom: 50px;
  padding-top: 200px;
}
.our-services-section .heading h3 {
  color: #191919;
}
.our-services-section .section-heading {
  text-align: center;
  width: 100%;
}
.our-services-section .section-heading h2 {
  color: #191919;
}
