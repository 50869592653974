.ethics-section {
  padding-bottom: 50px;
}

.ethics-section .title h3 {
  color: #0f5ca1;
}

.ethics-section .title,
.ethics-section .description,
.ethics-section .ethics-icon {
  text-align: center;
}
