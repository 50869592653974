.listings a:hover {
  text-decoration: none;
  color: #de3026;
}
.careers-section {
  padding-bottom: 50px;
  padding-top: 200px;
}
.careers-section .heading h3 {
  color: #191919;
}
.careers-section .section-heading {
  text-align: center;
  width: 100%;
}
.careers-section .section-heading h2 {
  color: #191919;
}
