.active {
  color: #de3026 !important;
}
.menu-link {
  padding: 25px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  text-align: center;
}
.mobile-menu-container {
  position: fixed;
  top: 7rem;
  display: flex;
  flex-direction: column;
  z-index: 999;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: transform 0.4s 0s ease, opacity 0.4s 0s ease,
    visibility 0s 0s linear;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb;
}

.mobile-menu-visible {
  position: fixed;
  top: 7rem;
  display: flex;
  flex-direction: column;
  z-index: 999;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: transform 0.4s 0s ease, opacity 0.4s 0s ease,
    visibility 0s 0s linear;
  background-color: #f7f7f7;
  border-top: 1px solid #ebebeb;
}

.mobile-menu-visible a {
  font-weight: 500;
  font-size: 17px;
}
.mobile-menu-visible a:hover {
  text-decoration: none;
  color: #de3026;
}
