.service-title h3 {
  color: #191919;
}
.service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 410px;
  align-items: center;
  padding: 30px 20px 20px 20px;
  background: #f7f7f7;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.service-container:hover {
  transform: translateY(-2px);
 
}
.service-container .service-icon {
  margin-bottom: 20px;
}
.service-container .service-icon img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  transition-duration: 0.3s;
}

.service-container .service-title {
  margin-bottom: 5px;
  text-align: center;
}

.service-container .service-desc {
  text-align: center;
}
.service-container .service-title h3 {
  font-size: 18px;
}
.service-container .service-button a {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1px;
  fill: #191919;
  color: #191919;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 3px;
}

.service-container .service-button a:hover {
  color: #de3026;
  text-decoration: none;
}

@media (max-width: 994px) and (min-width: 769px) {
  .service-container .service-button a {
    font-size: 10px;
    font-weight: 600;
  }
}

@media(max-width: 768px){
  .service-container {
    justify-content: flex-start;
    margin-bottom: 2rem;
    min-height: 210px;
  }
}
